<template>
  <v-card class="elevation-0">
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="12" md="4">
        <v-toolbar class="elevation-0">
          <div class="heading1 PrimaryFontColor">Production Logs</div>
        </v-toolbar>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="8">
        <v-toolbar class="elevation-0">
          <v-text-field
            outlined
            dense
            class="field_height field_label_size fontSize mt-6 mr-3"
            label="Total Water Produced"
            v-model="total_water_produced"
            readonly
          ></v-text-field>
          <v-text-field
            outlined
            dense
            class="field_height field_label_size fontSize mt-6 mr-3"
            label="Total Days in Operation"
            v-model="total_no_days"
            readonly
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select
            dense
            outlined
            class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
            :items="productionTypeItems"
            label="Production Type"
            v-model="production_log_type"
          ></v-select>
          <v-select
            dense
            v-if="production_log_type == 'WATER_PRODUCED'"
            outlined
            class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
            :items="plantTypeItems"
            label="Plant Type"
            v-model="plant_type"
          ></v-select>
          <v-dialog
            ref="dialog_start_date"
            v-model="model_start_date"
            :return-value.sync="start_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date"
                label="Start Date"
                prepend-inner-icon="mdi-calendar"
                dense
                outlined
                class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :max="end_date" v-model="start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="model_start_date = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog_start_date.save(start_date)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog_end_date"
            v-model="model_end_date"
            :return-value.sync="end_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date"
                label="End Date"
                prepend-inner-icon="mdi-calendar"
                dense
                outlined
                class="field_height field_label_size fontSize maxWidth mt-6 mr-3"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="end_date" :min="start_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="model_end_date = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog_end_date.save(end_date)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-btn
            small
            color="primary"
            @click="GetAllProductionLogsMethod(production_log_type, plant_type, start_date, end_date)"
            >Ok</v-btn
          >
        </v-toolbar>
      </v-col>
    </v-row>
    <v-card-text class="text-center" v-if="overlay == false">
      <apexchart align="left" height="250" type="bar" :options="getChartOptions" :series="series"> </apexchart>
      <apexchart type="line" height="250" :options="trendChartOptions" :series="lineseries"></apexchart>
    </v-card-text>
  </v-card>
</template>
<script>
import { GetAllProductionLogs } from "@/mixins/GetAllProductionLogs.js";
export default {
  mixins: [GetAllProductionLogs],
  data: () => ({
    productionTypeItems: [
      { text: "Water Produced", value: "WATER_PRODUCED" },
      { text: "UF Plant Inlet", value: "uf_plant_inlet" },
      { text: "DMF Manual Backwash", value: "dmf_manual_backwash_value" },
    ],
    plantTypeItems: ["UF", "RO1", "RO2"],
    start_date: "",
    end_date: "",
    production_log_type: "",
    plant_type: "",
    model_start_date: false,
    model_end_date: false,
    series: [
      {
        name: "Values",
        data: [],
      },
    ],
    lineseries: [
      {
        name: "Values",
        data: [],
      },
    ],
    trendChartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 450,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
      },
    },
  }),
  computed: {
    getChartOptions() {
      let chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Values",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {},
      };
      return chartOptions;
    },
  },
  mounted() {
    let time_stamp = [new Date().getTime()] / 1000 - [86400 * 30];
    this.start_date = new Date(time_stamp * 1000).toISOString().split("T")[0];
    this.end_date = new Date().toISOString().split("T")[0];
    this.production_log_type = "WATER_PRODUCED";
    this.plant_type = "UF";
    this.start_date.split();
    this.end_date.split();
    let Date1 = new Date(parseInt(this.start_date[1]), parseInt(this.start_date[1]) - 1, parseInt(this.start_date[1]));
    let Date2 = new Date(parseInt(this.end_date[0]), parseInt(this.end_date[1]) - 1, parseInt(this.end_date[2]));
    console.log("TotalDays", Date1.toDateString());
    console.log("TotalDays", Date2.toISOString());

    this.GetAllProductionLogsMethod(this.production_log_type, this.plant_type, this.start_date, this.end_date);
  },
};
</script>
