import { API } from "aws-amplify";
export const GetAllProductionLogs = {
  data() {
    return {
      GetAllProductionLogsList: {},
      overlay: false,
      RouterList: [],
      user_type: "",
      total_water_produced: "",
      total_no_days: "",
    };
  },
  methods: {
    async GetAllProductionLogsMethod(production_log_type, plant_type, start_date, end_date) {
      var self = this;
      self.overlay = true;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "getProductionLogs",
          organization_id: this.$store.getters.get_asset_object.organization_id,
          asset_id: this.$store.getters.get_asset_object.asset_id,
          production_log_type,
          plant_type: production_log_type == "WATER_PRODUCED" ? plant_type : undefined,
          start_date: Number(start_date.replaceAll("-", "")),
          end_date: Number(end_date.replaceAll("-", "")),
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false;
          if (!response.errorType) {
            this.total_water_produced = response.total_water_produced? response.total_water_produced.toFixed(3):0;
            this.total_no_days = response.total_days_of_operation? response.total_days_of_operation:0;
            console.log("Total_no_day", this.total_no_days);
            this.getChartOptions.xaxis.categories = response.data.items.map((option) => option.log_date);
            this.getChartOptions.yaxis.title.text =
              production_log_type == "WATER_PRODUCED"
                ? `${plant_type} Water Produced`
                : production_log_type.replaceAll("_", " ").toUpperCase();
            this.trendChartOptions.xaxis.categories = response.data.items.map((option) => option.log_date);
            this.series[0].data = response.data.items.map((option) =>
              production_log_type == "WATER_PRODUCED"
                ? option[`${plant_type.toLowerCase()}_water_produced`]
                : option[production_log_type]
            );
            this.lineseries[0].data = response.data.items.map((option) =>
              production_log_type == "WATER_PRODUCED"
                ? option[`${plant_type.toLowerCase()}_water_produced`]
                : option[production_log_type]
            );
          } else {
            this.getChartOptions.xaxis.categories = [];
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
